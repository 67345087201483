<template>
  <div>
    <div v-if="this.$route.params.uuid" class="alert alert-warning">
      <div class="alert-body">
        <h6 class="text-warning mb-0">
          Você optou por duplicar o pedido de compra.
        </h6>
      </div>
    </div>
    <b-form class="m-0 mt-1" ref="formFilter" @submit.prevent="submitCreate">
      <div class="form-row">
        <div class="col-md-8">
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"> Dados gerais </b-card-title>
                <b-card-sub-title>
                  Os dados inseridos abaixo serão utilizados ao emitir a nota
                  fiscal.
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for=""> Tipo de documento </label>
                    <v-select
                      :searchable="false"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.document_type"
                      :options="optionsDocumentTypes"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Fornecedor
                    </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.supplier"
                      placeholder="Digite o nome"
                      :options="optionsSuppliers"
                      @search="fetchSupplier"
                      :class="{ 'is-invalid': $v.item.supplier.$error }"
                      @input="supplierSelected"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group mb-md-0">
                    <label for="">
                      Nº Documento
                      <i
                        class="bi bi-question-circle"
                        v-b-tooltip.hover.top="
                          'Referenciar documento fora da Seducar.'
                        "
                      ></i>
                    </label>
                    <input
                      type="text"
                      v-model="item.document_number"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i> Categoria
                    </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.category"
                      placeholder="Digite o nome"
                      :options="optionsCategories"
                    >
                      <span
                        slot="no-options"
                        :class="{ 'is-invalid': $v.item.category.$error }"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Histórico PC
                    </label>
                    <v-select
                      :searchable="true"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.plan"
                      :options="optionsPlans"
                      :class="{ 'is-invalid': $v.item.plan.$error }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1">
                  <i class="text-danger bi bi-record-circle"></i>
                  Conta bancária
                </b-card-title>
                <b-card-sub-title>
                  A conta bancária pode ser alterada posteriormente para cada
                  pagamento.
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <v-select
                label="title"
                :searchable="true"
                :clearable="false"
                :options="optionsBankAccounts"
                :reduce="(option) => option.code"
                v-model="item.bank_account"
                :class="{ 'is-invalid': $v.item.bank_account.$error }"
                placeholder="Conta bancária"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </b-card-body>
          </b-card>
          <!-- TOTAL E PAGAMENTOS / PARCELAMENTOS -->
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1">Total e Pagamentos </b-card-title>
                <b-card-sub-title>
                  Abaixo serão listadas os próximos pagamentos.
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-row" style="align-items: center">
                <div class="col-md-3">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Total
                    </label>
                    <money
                      class="form-control"
                      v-bind="money"
                      v-model="item.total"
                      @blur.native="setTotal"
                      @focus.native="captureInitialValue(item)"
                      :class="{
                        'is-invalid': $v.item.total.$error,
                      }"
                    ></money>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Pagamentos
                    </label>
                    <input
                      type="tel"
                      v-mask="['####']"
                      :class="{ 'is-invalid': $v.item.installments.$error }"
                      v-model="item.installments"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group mb-md-0 mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Vencimento
                      <i
                        class="bi bi-question-circle"
                        v-b-tooltip.hover.top="
                          'Escolha o dia para vencimento. Ex.: Todo dia 5 do mês.'
                        "
                      ></i>
                    </label>
                    <input
                      class="form-control"
                      :class="{ 'is-invalid': $v.item.installment_due.$error }"
                      v-model="item.installment_due"
                      v-mask="['##']"
                      max="31"
                      placeholder="1 à 31"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group mb-0">
                    <label for="" class="invisible">a</label>
                    <button
                      type="button"
                      @click="generateInstallments"
                      class="btn btn-block btn-primary"
                      :disabled="!canGenerateInstallments"
                    >
                      Gerar
                    </button>
                  </div>
                </div>
              </div>

              <hr v-if="installments.length" />

              <div v-if="installments.length" class="mb-2">
                <label for="due-date-picker" class="form-label"
                  >Se desejar, altere a data de vencimento de todas as parcelas
                  para:</label
                >
                <flat-pickr
                  v-model="selectedDueDate"
                  id="due-date-picker"
                  class="form-control"
                  placeholder="Selecione uma data para todas as parcelas"
                  :config="{
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    locale: 'pt',
                  }"
                />
                <b-button
                  type="button"
                  @click="updateInstallmentsDate"
                  class="btn btn-success w-100 mt-2"
                  :disabled="!selectedDueDate"
                >
                  Confirmar
                </b-button>
              </div>

              <b-card no-body>
                <b-table
                  v-if="installments.length"
                  sticky-header
                  :items="installments"
                  responsive
                  :fields="installmentsTable"
                  show-empty
                  empty-text="Nenhum registro encontrado"
                  class="position-relative"
                  style="transition: background-color 0.5s ease"
                >
                  <template #cell(installment)="{ item }">
                    <strong class="relative">
                      {{ item.installment }}
                    </strong>
                  </template>

                  <template #cell(date)="{ item }">
                    <flat-pickr
                      v-model="item.date"
                      class="form-control"
                      :config="{
                        altInput: true,
                        dateFormat: 'Y-m-d',
                        altFormat: 'd/m/Y',
                        locale: 'pt',
                      }"
                    />
                  </template>

                  <template #cell(total)="{ item, index }">
                    <div class="input-group mb-0">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          >R$</span
                        >
                      </div>
                      <money
                        class="form-control"
                        v-model="item.total"
                        @blur.native="updateInstallments(index)"
                      />
                    </div>
                  </template>

                  <template #cell(actions)="{ item }">
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="deleteInstallment()"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                  </template>
                </b-table>
              </b-card>
            </b-card-body>
          </b-card>

          <div class="form-row mt-3 mb-2">
            <div class="col-md-11">
              <h4 class="mb-0">
                <i
                  class="text-danger bi bi-record-circle"
                  style="font-size: 0.9rem"
                ></i>
                Rateio
              </h4>
              <p class="mb-0" style="font-size: 0.9rem">
                Aqui, você cadastrará o rateio, selecionando a unidade de
                negócio e a estratégica.
              </p>
            </div>

            <div class="col-md-1">
              <b-button
                v-if="Number(item.total) > 0"
                :disabled="item.strategic_units.length > 0 ? true : false"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addStrategicUnit"
                class="btn-block"
              >
                <i class="bi bi-plus"></i>
              </b-button>
            </div>
          </div>

          <b-card
            class="mb-0 card-header-custom-actions mb-1"
            header-tag="header"
            v-for="(strategicElement, i) in item.strategic_units"
            :key="`business-units-key-${i}`"
          >
            <b-card-text class="mt-0">
              <div class="form-row align-items-center">
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <v-select
                      label="title"
                      @input="strategicUnitSelected(i, strategicElement)"
                      v-model="strategicElement.strategic_unit"
                      item-text="title"
                      item-value="code"
                      placeholder="Unidade estratégica"
                      :options="optionsStrategicUnits"
                      :clearable="false"
                      @search="fetchStrategics"
                      :class="{
                        'is-invalid':
                          $v.item.strategic_units.$each[i].strategic_unit
                            .$error,
                      }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <!-- <div class="col-md-3">
                  <div class="form-group mb-0 text-center">
                    <money
                      class="form-control"
                      v-model="strategicElement.total"
                      @focus.native="captureInitialValue(strategicElement)"
                      @blur.native="
                        updateRateioStrategicValue(strategicElement)
                      "
                    ></money>
                  </div>
                </div> -->

                <!-- <div class="col-md-2">
                  <div class="form-group mb-0">
                    <money
                      class="form-control"
                      v-bind="percentFormat"
                      v-model="strategicElement.percent"
                      :class="{
                        'is-invalid':
                          $v.item.strategic_units.$each[i].percent.$error,
                      }"
                      @focus.native="captureInitialPercent(strategicElement)"
                      @blur.native="
                        updateRateioStrategicPercent(strategicElement)
                      "
                    ></money>
                  </div>
                </div> -->

                <!-- <div class="col-md-1">
                  <div class="form-group mb-0">
                    <b-button
                      class="btn-trash"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="danger"
                      block
                      @click="removeAtStrategicUnit(i)"
                    >
                      <i class="bi bi-trash3"></i>
                    </b-button>
                  </div>
                </div> -->

                <div class="col-md-12">
                  <b-card-text class="card-strategic p-1 mt-1">
                    <div class="form-row" style="align-items: center">
                      <div class="col-md-11">
                        <h5 class="mb-0">
                          <i
                            class="text-danger bi bi-record-circle"
                            style="font-size: 0.9rem"
                          ></i>
                          Unidades de negócio
                        </h5>
                      </div>

                      <div class="col-md-1">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          style="height: 33px; padding: 0px !important"
                          @click="addBusinessUnit(strategicElement)"
                          class="btn-block"
                        >
                          <i class="bi bi-plus"></i>
                        </b-button>
                      </div>
                    </div>

                    <div
                      class="form-row align-items-center mt-1"
                      :class="{
                        'mb-1': index !== strategicElement.business.length - 1,
                      }"
                      v-for="(
                        businessElement, index
                      ) in strategicElement.business"
                      :key="`business-strategic-key-${index}`"
                    >
                      <div class="col-md-6">
                        <div class="form-group mb-0">
                          <v-select
                            label="title"
                            v-model="businessElement.business_unit"
                            item-text="title"
                            item-value="code"
                            :clearable="false"
                            placeholder="Unidade de negócio"
                            :options="strategicElement.strategic_unit.business"
                            @search="fetchBusinessUnits"
                            @input="businessUnitSelected(businessElement)"
                          >
                            <span slot="no-options">
                              Nenhum registro encontrado
                            </span>
                          </v-select>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group mb-0">
                          <money
                            class="form-control"
                            @focus.native="
                              captureInitialPercent(businessElement)
                            "
                            @blur.native="
                              updateRateioBusinessUnitPercent(
                                businessElement,
                                strategicElement
                              )
                            "
                            v-model="businessElement.percent"
                            v-bind="percentFormat"
                          ></money>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group mb-0">
                          <money
                            class="form-control"
                            @focus.native="captureInitialValue(businessElement)"
                            @blur.native="
                              updateRateioBusinessUnitTotal(
                                businessElement,
                                strategicElement
                              )
                            "
                            v-model="businessElement.total"
                          ></money>
                        </div>
                      </div>

                      <div class="col-md-1">
                        <div class="form-group mb-0">
                          <b-button
                            class="btn-trash"
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="danger"
                            block
                            @click="
                              removeAtBusinessUnit(index, strategicElement)
                            "
                          >
                            <i class="bi bi-trash3"></i>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-card-text>

                  <strategic-summary :strategicElement="strategicElement" />
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card v-if="!item.strategic_units.length">
            <b-card-text
              >Selecione o <strong>Histórico PC</strong> e gere os
              pagamentos.</b-card-text
            >
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header class="mb-0">
              <div>
                <div class="form-row">
                  <div class="col-md-11">
                    <b-card-title class="mb-1"> Arquivos </b-card-title>
                    <b-card-sub-title>
                      Você pode adicionar 1 ou mais arquivos relacionado a este
                      pedido de compra.
                    </b-card-sub-title>
                  </div>
                  <div class="col-md-1">
                    <button
                      @click="selectFiles"
                      type="button"
                      class="btn btn-block btn-primary"
                      style="
                        padding-left: 5px !important;
                        padding-right: 5px !important;
                      "
                    >
                      <i class="bi bi-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body class="p-2">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <input
                      id="files"
                      type="file"
                      multiple
                      class="form-control-file hidden"
                      @change="previewDocs"
                      accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    />

                    <p
                      v-if="!item.files.length"
                      class="py-3 text-center mb-0"
                      style="
                        border: 1px solid rgb(222 222 222);
                        border-radius: 4px;
                        border-style: dashed;
                        font-size: 1.1rem;
                      "
                    >
                      <i class="bi bi-file-earmark-text"></i>
                      Selecionar arquivos
                      <small class="text-center mt-1 d-block">
                        Selcione apenas arquivos nos formatos: <br />
                        xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf
                      </small>
                    </p>

                    <label class="d-block text-center mb-0">
                      <ul
                        class="list-group list-group-light"
                        id="array-files"
                        v-if="item.files.length"
                      >
                        <li
                          class="list-group-item border-0 rounded-5 mb-5-px text-left px-2"
                          v-for="(doc, index) in item.files"
                          :key="index"
                        >
                          <div class="form-row">
                            <div
                              class="col-md-9 text-left"
                              style="font-size: 0.9rem"
                            >
                              Arquivo {{ index + 1 }}
                            </div>
                            <div
                              class="col-md-2 text-center"
                              style="font-size: 0.9rem"
                            >
                              {{ doc.size | bytesToMB(2) }}
                            </div>
                            <div
                              class="col-md-1 text-center"
                              style="font-size: 0.9rem"
                            >
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                              >
                                <i class="bi bi-trash3"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </label>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>

        <div class="col-md-4">
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-0"> Detalhes </b-card-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-group mb-0">
                <label for="">Histórico</label>
                <textarea
                  name=""
                  class="form-control"
                  v-model="item.description"
                  rows="3"
                ></textarea>
              </div>
            </b-card-body>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"> Tags </b-card-title>
                <b-card-sub-title>
                  A tag ajudará você a pesquisar
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <b-form-tags
                separator=",;"
                no-add-on-enter
                class="form-control mb-1"
                placeholder="Escreva aqui..."
                v-model="item.tags"
                rows="3"
              />
              <small>Separe os termos usando vírgula.</small>
            </b-card-body>
          </b-card>

          <BtnSaveAndBack
            :disabled="!canSave"
            routerBack="orders-purchases-list"
            variant="success"
            :submited="submited"
            text="Cadastrar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BCardBody,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BCardFooter,
  BFormTags,
  BTable,
  BInputGroupAppend,
  BButton,
  BCardText,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import { Money } from "v-money";

import { required, minValue, maxValue } from "vuelidate/lib/validators";

import vSelect from "vue-select";
import "swiper/css/swiper.css";

import Ripple from "vue-ripple-directive";

import BtnSaveAndBack from "@/components/button/SaveAndBack";

import moment from "moment";

import StrategicSummary from "@/views/components/purchase/StrategicSummary.vue";
import { reduce } from "lodash";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BForm,
    BtnSaveAndBack,
    BCard,
    BTable,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VBTooltip,
    BCardSubTitle,
    BFormCheckbox,
    BInputGroup,
    BFormInput,
    BCardFooter,
    BInputGroupAppend,
    vSelect,
    BFormTags,
    Money,
    flatPickr,
    BButton,
    BCardText,
    StrategicSummary,
  },
  data() {
    return {
      selectedDueDate: null,
      submited: false,
      item: {
        tags: [],
        supplier: "",
        issue_date: "",
        total: "",
        due_date: "",
        category: "",
        document_number: "",
        occurrence: "",
        note: "",
        document_type: "",
        plan: "",
        description: "",
        strategic_units: [],
        installments: "",
        installment_first: "",
        installment_due: "",
        files: [],
        bank_account: "",
      },
      installmentsTable: [
        {
          key: "installment",
          label: "Pagamento",
          class: "text-center",
          thStyle: "width: 50px",
        },
        {
          key: "date",
          label: "Vencimento",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "total",
          label: "Valor",
          class: "text-center",
          thStyle: "width: 150px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 50px",
        },
      ],
      optionsSuppliers: [],
      optionsDocumentTypes: [],
      optionsCategories: [],
      optionsPlans: [],
      optionsBusinessUnits: [],
      optionsStrategicUnits: [],
      optionsBankAccountsFilter: [],
      optionsBankAccounts: [],
      installments: [],
      optionsStatus: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: ".",
        thousands: ".",
        prefix: "",
        suffix: "%",
        precision: 2,
        masked: false,
      },
      nextBusinessUnit: 1,
      nextStrategicUnit: 1,
      strategicUnitLimitPercentage: {
        total: 0,
        limit: 100,
        diference: 0,
      },
    };
  },
  validations: {
    item: {
      bank_account: {
        required,
      },
      supplier: {
        required,
      },
      total: {
        required,
        minValue: minValue(0.01),
      },
      plan: {
        required,
      },
      category: {
        required,
      },
      installments: {
        required,
        minValue: minValue(1),
      },
      installment_due: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(31),
      },
      strategic_units: {
        $each: {
          strategic_unit: {
            required,
          },
          percent: {
            required,
          },
          total: {
            required,
          },
        },
      },
    },
  },
  computed: {
    canGenerateInstallments() {
      return (
        this.item.total > 0 &&
        this.item.installments > 0 &&
        this.item.installment_due >= 1 &&
        this.item.installment_due <= 31
      );
    },
    isRateioOk() {
      const total = this.item.strategic_units.reduce(
        (total, item) => total + item.total_currency,
        0
      );

      if (total !== this.item.total) {
        return false;
      }

      return true;
    },
    canSave() {
      if (!this.installments.length) return false;
      if (
        !this.item.strategic_units.length ||
        !this.item.strategic_units[0]?.strategic_unit ||
        (this.item.strategic_units[0]?.business?.length > 0 &&
          !this.item.strategic_units[0]?.business[0]?.business_unit)
      ) {
        return false;
      }

      const totalParcelas = this.installments.reduce((acc, parcela) => {
        return acc + parseFloat(parcela.total || 0);
      }, 0);

      const margemErro = 0.1;

      if (Math.abs(totalParcelas - this.item.total) > margemErro) {
        return false;
      }

      if (this.item.strategic_units.length > 0) {
        if (this.item.strategic_units[0].diferences.currency !== 0) {
          return false;
        }
      }

      return true;
    },
  },
  methods: {
    supplierSelected(e) {
      this.item.plan = "";

      if (e.plan) {
        this.item.plan = e.plan;
      }
    },
    setTotal() {
      this.item.strategic_units.forEach((strategicUnit) => {
        this.updateRateioStrategicPercent(strategicUnit);
      });
    },
    updateRateioBusinessUnitPercent(businessElement, strategicUnit) {
      businessElement.total =
        strategicUnit.total * (businessElement.percent / 100);

      this.updateStrategicUnitsSum(this.item.strategic_units);
    },

    updateRateioBusinessUnitTotal(businessElement, strategicUnit) {
      businessElement.percent =
        (businessElement.total / strategicUnit.total) * 100;

      this.updateStrategicUnitsSum(this.item.strategic_units);
    },

    updateRateioStrategicPercent(strategicUnit) {
      const totalPercentOfStrategics = this.item.strategic_units.reduce(
        (total, item) => total + item.percent,
        0
      );

      if (totalPercentOfStrategics > 100) {
        strategicUnit.percent = strategicUnit.initalPercent;
        this.showToastError(
          "O valor total dos itens estratégicos ultrapassa o valor total da porcentagem"
        );
        return;
      }

      if (strategicUnit.percent > 100) {
        strategicUnit.percent = 100;
      }

      strategicUnit.total = this.item.total * (strategicUnit.percent / 100);

      strategicUnit.business.map((businessElement) => {
        this.updateRateioBusinessUnitPercent(businessElement, strategicUnit);
      });

      this.updateStrategicUnitsSum(this.item.strategic_units);
    },
    updateRateioStrategicValue(strategicUnit) {
      const totalValueOfStrategics = this.item.strategic_units.reduce(
        (total, item) => total + item.total,
        0
      );

      if (totalValueOfStrategics > this.item.total) {
        strategicUnit.total = strategicUnit.initalValue;
        this.showToastError(
          "O valor total dos itens estratégicos ultrapassa o valor total da compra"
        );
        return;
      }
      strategicUnit.percent = parseFloat(
        (strategicUnit.total / this.item.total) * 100
      );

      strategicUnit.business.map((businessElement) => {
        this.updateRateioBusinessUnitPercent(businessElement, strategicUnit);
      });

      this.updateStrategicUnitsSum(this.item.strategic_units);
    },
    addBusinessUnit(strategicUnit) {
      strategicUnit.business.push({
        percent: 0,
        total: 0,
        business: "",
      });

      const newPercent = 100 / strategicUnit.business.length;
      const newTotal = strategicUnit.total / strategicUnit.business.length;

      strategicUnit.business.forEach((business) => {
        business.percent = newPercent;
        business.total = newTotal;
      });

      this.updateStrategicUnitsSum(this.item.strategic_units);
    },
    addStrategicUnit() {
      this.nextStrategicUnit = this.nextStrategicUnit + 1;
      this.item.strategic_units.push({
        id: this.nextStrategicUnit,
        percent: 0,
        total: 0,
        strategic_unit: "",
        business: [],
        total_percent: 0,
        total_currency: 0,
        diferences: {
          currency: 0,
          percent: 0,
        },
      });

      const newPercent = 100 / this.item.strategic_units.length;
      const newTotal = this.item.total / this.item.strategic_units.length;

      this.item.strategic_units.forEach((strategicUnit) => {
        strategicUnit.percent = newPercent;
        strategicUnit.total = newTotal;

        if (strategicUnit.business.length === 0) {
          strategicUnit.business.push({
            percent: 100,
            total: newTotal,
            business: "",
          });
        } else {
          const businessPercent = 100 / strategicUnit.business.length;
          const businessTotal = newTotal / strategicUnit.business.length;
          strategicUnit.business.forEach((business) => {
            business.percent = businessPercent;
            business.total = businessTotal;
          });
        }
      });

      this.updateStrategicUnitsSum(this.item.strategic_units);
    },
    updateInstallmentsDate() {
      if (!this.selectedDueDate) return;

      const date = moment(this.selectedDueDate).format("YYYY-MM-DD");
      this.installments = this.installments.map((installment, index) => ({
        ...installment,
        date,
      }));
    },
    updateStrategicUnitsSum(strategic_units) {
      strategic_units.forEach((strategicUnit) => {
        strategicUnit.total_currency = 0;
        strategicUnit.total_percent = 0;

        strategicUnit.business.forEach((business) => {
          strategicUnit.total_currency = parseFloat(
            (strategicUnit.total_currency + business.total).toFixed(2)
          );
          strategicUnit.total_percent = parseFloat(
            (strategicUnit.total_percent + business.percent).toFixed(2)
          );
        });

        const percentDifference = parseFloat(
          (100 - strategicUnit.total_percent).toFixed(2)
        );
        const currencyDifference = parseFloat(
          (strategicUnit.total - strategicUnit.total_currency).toFixed(2)
        );

        if (
          Math.abs(percentDifference) <= 0.05 &&
          strategicUnit.business.length > 0
        ) {
          const lastBusiness =
            strategicUnit.business[strategicUnit.business.length - 1];
          lastBusiness.percent = parseFloat(
            (lastBusiness.percent + percentDifference).toFixed(2)
          );
        }

        if (
          Math.abs(currencyDifference) <= 0.05 &&
          strategicUnit.business.length > 0
        ) {
          const lastBusiness =
            strategicUnit.business[strategicUnit.business.length - 1];
          lastBusiness.total = parseFloat(
            (lastBusiness.total + currencyDifference).toFixed(2)
          );
        }

        strategicUnit.total_currency = strategicUnit.business.reduce(
          (sum, business) => parseFloat((sum + business.total).toFixed(2)),
          0
        );
        strategicUnit.total_percent = strategicUnit.business.reduce(
          (sum, business) => parseFloat((sum + business.percent).toFixed(2)),
          0
        );

        strategicUnit.diferences.currency = parseFloat(
          (strategicUnit.total - strategicUnit.total_currency).toFixed(2)
        );
        strategicUnit.diferences.percent = parseFloat(
          (100 - strategicUnit.total_percent).toFixed(2)
        );
      });
    },

    updateInstallments(index) {
      let novoValor = parseFloat(this.installments[index].total);
      if (isNaN(novoValor) || novoValor <= 0) {
        this.installments[index].total = this.installments[index].total || 0;
        return;
      }

      let somaParcelasAnteriores = 0;
      for (let i = 0; i < index; i++) {
        somaParcelasAnteriores += parseFloat(this.installments[i].total);
      }

      let novoTotalRestante =
        this.item.total - somaParcelasAnteriores - novoValor;

      if (novoTotalRestante < 0) {
        this.installments[index].total =
          this.item.total - somaParcelasAnteriores;
        return;
      }

      let parcelasRestantes = this.item.installments - index - 1;

      if (parcelasRestantes > 0) {
        let novoValorParcela = (novoTotalRestante / parcelasRestantes).toFixed(
          2
        );

        if (novoValorParcela < 0) {
          return;
        }

        for (let i = index + 1; i < this.item.installments; i++) {
          this.installments[i].total = novoValorParcela;
        }
      }
    },
    strategicUnitSelected(indexStrategicUnit, element) {
      if (element.strategic_unit) {
        element.code = element.strategic_unit.code;
      }

      if (this.item.strategic_units[indexStrategicUnit].business.length === 0) {
        this.item.strategic_units[indexStrategicUnit].business.push({
          percent: 100,
          total: this.item.strategic_units[indexStrategicUnit].total,
          business_unit: "",
        });
      }
    },
    businessUnitSelected(v) {
      v.code = v.business_unit ? v.business_unit.code : "";
    },
    removeAtStrategicUnit(index) {
      this.item.strategic_units.splice(index, 1);
    },
    removeAtBusinessUnit(businessIndex, strategicUnit) {
      strategicUnit.business.splice(businessIndex, 1);

      const newPercent = 100 / strategicUnit.business.length;
      const newTotal = strategicUnit.total / strategicUnit.business.length;

      strategicUnit.business.forEach((business) => {
        business.percent = newPercent;
        business.total = newTotal;
      });
      this.updateStrategicUnitsSum(this.item.strategic_units);
    },
    refreshCurrentBusinessUnits() {
      const percent = 100;
      const units = this.item.strategic_units.length;
      const total = this.item.total / units;

      this.nextBusinessUnit = this.nextBusinessUnit + 1;

      for (let index = 0; index < this.item.strategic_units.length; index++) {
        this.item.strategic_units[index].percent = percent / units;
        this.item.strategic_units[index].total = total;
      }
    },
    deleteInstallment() {
      this.item.installments = this.item.installments - 1;
      this.generateInstallments();
    },
    generateInstallments() {
      this.installments = [];
      const today = moment();
      let startMonth = today.month();

      if (today.date() > this.item.installment_due) {
        // Se a data do dia atual for maior que a data de vencimento, adiciona 1 ao mês
        startMonth += 1;
      }

      for (let index = 0; index < this.item.installments; index++) {
        this.installments.push({
          installment: index + 1,
          total: this.item.total / this.item.installments,
          date: moment()
            .month(startMonth + index)
            .date(this.item.installment_due)
            .format("YYYY-MM-DD"),
        });
      }
    },
    captureInitialValue(element) {
      if (element.total) {
        element.initalValue = element.total;
      }
    },
    captureInitialPercent(element) {
      if (element.total) {
        element.initalPercent = element.percent;
      }
    },
    showToastError(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "XIcon",
          variant: "danger",
        },
      });
    },
    selectFiles() {
      this.$el.querySelector("#files").click();
    },
    deleteFile(event) {
      this.item.docs.splice(this.item.docs.indexOf(event), 1);
    },
    previewDocs(event) {
      const input = event.target;
      if (input.files) {
        const files = input.files;

        this.item.files = files;
      }
    },
    async submitCreate() {
      this.$v.item.$touch();

      if (!this.$v.item.$error) {
        if (!this.isRateioOk) {
          this.showToastError(
            "1 ou mais valores foram digitados incorretamente no rateio."
          );
          return;
        }

        const formData = new FormData();

        formData.append(
          "category",
          this.item.category ? this.item.category.code : ""
        );
        formData.append("description", this.item.description);
        formData.append("document_number", this.item.document_number);
        formData.append("document_type", this.item.document_type.code);
        formData.append("installment_due", this.item.installment_due);
        formData.append("installments", this.item.installments);
        formData.append("plan", this.item.plan.code);
        formData.append(
          "strategic_units",
          JSON.stringify(this.item.strategic_units)
        );
        formData.append("supplier", this.item.supplier.code);
        formData.append("tags", this.item.tags);
        formData.append("total", this.item.total);
        formData.append("order_type", "avulso");
        formData.append("purchase_order_status_id", 3);
        formData.append("purchase_order_step_id", 3);
        formData.append("bank_account", this.item.bank_account);

        var files = this.item.files;

        for (var index = 0; index < files.length; index++) {
          formData.append("files[]", files[index]);
        }

        this.submited = true;
        this.item.all_installments = this.installments;

        formData.append(
          "all_installments",
          JSON.stringify(this.item.all_installments)
        );

        this.$store
          .dispatch("OrderPurchase/store", formData)
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "orders-purchases-payments-list" });
          })
          .catch(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async fetchSupplier(term) {
      if (term.length > 1) {
        this.$store.dispatch("Supplier/search", term).then((res) => {
          this.optionsSuppliers = res;
        });
      }
    },
    async fetchStrategics(term) {
      if (term.length > 2) {
        this.optionsStrategicUnits = await this.$store.dispatch(
          "StrategicUnit/forSelect",
          term
        );
      }
    },
    async fetchBusinessUnits(term) {
      if (term.length > 2) {
        this.optionsBusinessUnits = await this.$store.dispatch(
          "BusinessUnit/forSelect",
          term
        );
      }
    },
    async getData() {
      this.$store
        .dispatch("OrderPurchase/edit", this.$route.params.uuid)
        .then((res) => {
          this.item = res;

          this.installments = res.all_installments;
          this.item.installments = this.installments.length;
        });
    },
  },
  mounted() {
    this.$store.dispatch("OrderPurchase/forSelectStatus").then((res) => {
      this.optionsStatus = res;
    });

    this.$store.dispatch("StrategicUnit/forSelect", "").then((res) => {
      this.optionsStrategicUnits = res;
    });

    this.$store.dispatch("OrderPurchase/forSelectPlans").then((res) => {
      this.optionsPlans = res;
    });

    this.$store.dispatch("OrderPurchase/forSelectDocumentTypes").then((res) => {
      this.optionsDocumentTypes = res;
    });

    this.$store.dispatch("OrderPurchase/forSelectCategories").then((res) => {
      this.optionsCategories = res;
    });

    this.$store.dispatch("BankAccount/forSelect", { term: "" }).then((res) => {
      this.optionsBankAccounts = res;
      this.optionsBankAccountsFilter = res;
      this.optionsBankAccountsFilter.push({
        code: 0,
        title: "Nenhuma",
      });
    });

    if (this.$route.params.uuid) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
.card-strategic {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 7%);
  border-radius: 0.428rem;
  border: 1px solid #f3f3f3;
}
.btn-trash {
  text-align: center;
  padding: 0px !important;
  height: 44px;
}
</style>
